<template>
    <BT-Blade-Items
        addBladeName="supply-pointer"
        :bladesData="bladesData"
        bladeName="supply-pointers"
        canAdd
        canDelete
        canSearchLocal
        :headers="[
            { text: 'Supply Location', value: 'location.locationName', title: 1, searchable: true },
            { text: 'Weekdays', value: 'weekdays', subtitle: 1, searchable: true }]"
        navigation="supply-pointers"
        useServerPagination
        title="Supply Pointers"
        :defaultBladeWidth="350">
    </BT-Blade-Items>
</template>

<script>
export default {
    name: 'Supply-Pointers-Blade',
    props: {
        bladesData: null
    }
}
</script>